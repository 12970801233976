export const environment = {
  production: false,
  apiUrl: 'https://qa-api.walkdotin.com/api/',
  automationsApiUrl: 'https://qa-api.walkdotin.com/automations/v1/',
  shortUrl: 'https://qa-api.walkdotin.com/',
  firebaseApiKey: 'AIzaSyCgxDJW-ejH-tHwiP-r707OC6iB41g9wzA',
  firebaseAuthDomain: 'walkdotin-1568740645868.firebaseapp.com',
  firebaseDatabaseURL: 'https://walkdotin-1568740645868.firebaseio.com',
  firebaseProjectId: 'walkdotin-1568740645868',
  firebaseStorageBucket: 'walkdotin-1568740645868.appspot.com',
  firebaseMessagingSenderId: '430616670457',
  firebaseAppId: '1:430616670457:web:898182d0f9aca9322b3228',
  firebaseMeasurementId: 'G-1Z3CG53244',
  firebasePublicVapidKey: 'BFotb7lpsncIRYTvf9nmNw7Q8h2zQQ2SCJ2a3-sNtq9LNBKp4hR4ag_oFHo9q93U2jr98tRrdL0B0v1o82HthYk'
};
